import { clamp } from "lodash";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export enum DockPosition {
  Right = "right",
  Bottom = "bottom",
  Center = "center",
  Full = "full",
}

export enum StepsDisplayMode {
  Canvas = "canvas",
  Table = "table",
  Waterfall = "waterfall",
}

const SIDEBAR_DEFAULT_WIDTH = 320;
const SIDEBAR_MAX_WIDTH = 440;
const SIDEBAR_MIN_WIDTH = 200;
const DRAWER_DEFAULT_SIZE = 50;

export function clampSidebarWidth(value: number) {
  return clamp(value, SIDEBAR_MIN_WIDTH, SIDEBAR_MAX_WIDTH);
}

interface BuildPreferences {
  stepsDisplayMode: StepsDisplayMode;
  setStepsDisplayMode: (view: StepsDisplayMode) => void;

  dockPosition: DockPosition;
  setDockPosition: (dockPosition: DockPosition) => void;

  /**
   * Resized sidebar width (as pixels)
   */
  sidebarWidth?: number;
  setSidebarWidth: (width: number) => void;

  /**
   * Resized drawer height (in percentage)
   */
  resizedDrawerHeight?: number;
  setResizedDrawerHeight: (height: number) => void;
  resetDrawerHeight: () => void;

  /**
   * Resized drawer width (in percentage)
   */
  resizedDrawerWidth?: number;
  setResizedDrawerWidth: (width: number) => void;
  resetDrawerWidth: () => void;

  /**
   * Table view sort column and direction
   */
  sortColumn?: string | null;
  setSortColumn: (column: string | null) => void;
  sortDirection?: "asc" | "desc" | null;
  setSortDirection: (direction: "asc" | "desc" | null) => void;

  /**
   * Expanded/collapsed states in the sidebar
   */
  expandedStates?: string[];
  setExpandedStates: (states: string[]) => void;
}

/**
 * A store for build page preferences persisted into local storage to ensure
 * the user's preferences are remembered between sessions.
 */
export const useBuildPreferencesStore = create<BuildPreferences>()(
  persist(
    (set) => ({
      stepsDisplayMode: StepsDisplayMode.Canvas,
      dockPosition: DockPosition.Bottom,
      resizedDrawerHeight: DRAWER_DEFAULT_SIZE,
      resizedDrawerWidth: DRAWER_DEFAULT_SIZE,
      sidebarWidth: SIDEBAR_DEFAULT_WIDTH,

      setStepsDisplayMode: (mode: StepsDisplayMode) => set(() => ({ stepsDisplayMode: mode })),

      setExpandedStates: (states: string[]) => set(() => ({ expandedStates: states })),

      setSortColumn: (column: string | null) => set(() => ({ sortColumn: column })),
      setSortDirection: (direction: "asc" | "desc" | null) =>
        set(() => ({ sortDirection: direction })),

      setSidebarWidth: (width: number) => {
        return set(() => ({ sidebarWidth: clampSidebarWidth(width) }));
      },

      setDockPosition: (dock: DockPosition) => set(() => ({ dockPosition: dock })),

      resetDrawerHeight: () => set(() => ({ resizedDrawerHeight: DRAWER_DEFAULT_SIZE })),
      setResizedDrawerHeight: (height: number) => set(() => ({ resizedDrawerHeight: height })),

      resetDrawerWidth: () => set(() => ({ resizedDrawerWidth: DRAWER_DEFAULT_SIZE })),
      setResizedDrawerWidth: (width: number) => set(() => ({ resizedDrawerWidth: width })),
    }),
    {
      name: "build-preferences",
      storage: createJSONStorage(() => localStorage),
      version: 1,
    },
  ),
);
