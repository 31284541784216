/* eslint-disable id-length */

import Icon from "app/components/shared/Icon";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { useHotKey } from "app/components/Playground/Controls/useHotKey";
import { DockPosition, useBuildPreferencesStore } from "../lib/useBuildPreferencesStore";
import { ResizeHandle } from "./ResizeHandle";
import { useMobileBreakpoint } from "../lib/useBreakpoints";
import useQueryParams from "../lib/useQueryParams";
import ToolbarButton, { ToolbarButtonGroup } from "./ToolbarButton";
import debounce from "lodash/debounce";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  actions?: React.ReactNode;
}

// TODO: If we can't find a way to get animations working as expected, we should remove this code.
const getVariants = (dock: DockPosition) => {
  switch (dock) {
    case DockPosition.Center:
      return {
        initial: {},
        animate: { scale: 1, transition: { type: "spring", duration: 0.25 } },
        exit: { transition: { duration: 0 } },
      };
    // No animation for bottom docked drawer
    case DockPosition.Bottom:
      return {
        initial: {},
        animate: { y: 0 },
        exit: { transition: { duration: 0 } },
      };
    // No animation for right docked drawer
    case DockPosition.Right:
      return {
        initial: {},
        animate: { x: 0 },
        exit: { transition: { duration: 0 } },
      };
  }
};

const RESIZE_DEBOUNCE_WAIT = 150;

/**
 * A collapsible drawer
 */
export const Drawer = ({ children, actions, onClose }: Props) => {
  const isMobile = useMobileBreakpoint();

  // Get the current dock position from the store, or default to full screen on mobile
  const dockPosition = useBuildPreferencesStore((state) =>
    isMobile ? DockPosition.Full : state.dockPosition,
  );

  const [resetDrawerWidth, resetDrawerHeight, setResizedDrawerHeight, setResizedDrawerWidth] =
    useBuildPreferencesStore((state) => [
      state.resetDrawerWidth,
      state.resetDrawerHeight,
      state.setResizedDrawerHeight,
      state.setResizedDrawerWidth,
    ]);

  const drawerRef = useRef<HTMLDivElement>(null);

  // The initial size is stored in a ref to avoid re-rendering the component when the size changes.
  const heightRef = useRef(useBuildPreferencesStore.getState().resizedDrawerHeight);
  const widthRef = useRef(useBuildPreferencesStore.getState().resizedDrawerWidth);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setWidth = useCallback(
    debounce((width) => setResizedDrawerWidth(width), RESIZE_DEBOUNCE_WAIT),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setHeight = useCallback(
    debounce((height) => setResizedDrawerHeight(height), RESIZE_DEBOUNCE_WAIT),
    [],
  );

  useHotKey("Escape", () => {
    onClose();
  });

  // Store a reference to the container element
  const containerRef = useRef<HTMLElement>();
  useEffect(() => {
    const container = document.getElementById("drawer-container");
    if (container) {
      containerRef.current = container;
    }
  }, []);

  // TODO: Work out where this comes from
  const margin = 15;

  /**
   * Resize the drawer when dragging the resize handle
   *
   * Hook into the mouse down event to start dragging the resize handle
   * and update the drawer size based on the mouse position, storing the
   * new size in a preferences store for persistence across sessions.
   *
   * Sizes are stored as percentages to allow for responsive resizing.
   */
  const resize = useCallback(
    (x: number, y: number) => {
      if (!drawerRef.current) {
        return;
      }

      // Prevent resizing when the drawer is expanded
      if (dockPosition === DockPosition.Full) {
        return;
      }

      // If the drawer is docked to bottom, resize the height (as a percentage)
      if (dockPosition === DockPosition.Bottom) {
        if (!containerRef.current?.clientHeight) {
          return resetDrawerHeight();
        }

        let height =
          ((window.innerHeight - y - margin) / containerRef?.current?.clientHeight) * 100;

        if (height > 100) {
          height = 100;
        } else if (height < 5) {
          height = 5;
        }

        drawerRef.current.style.height = `${height}%`;
        setHeight(height);
      }

      // If the drawer is docked to right, resize the width (as a percentage)
      if (dockPosition === DockPosition.Right) {
        if (!containerRef.current?.clientWidth) {
          return resetDrawerWidth();
        }

        let width = ((window.innerWidth - x - margin) / containerRef?.current?.clientWidth) * 100;

        if (width > 100) {
          width = 100;
        } else if (width < 5) {
          width = 5;
        }

        drawerRef.current.style.width = `${width}%`;
        setWidth(width);
      }
    },
    [dockPosition],
  );

  const style: React.CSSProperties = {};
  if (dockPosition === DockPosition.Right) {
    style.width = `${widthRef.current}%`;
  }

  if (dockPosition === DockPosition.Bottom) {
    style.height = `${heightRef.current}%`;
  }

  return (
    <motion.div
      ref={drawerRef}
      key={dockPosition}
      data-position={dockPosition}
      data-testid="drawer"
      style={style}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={getVariants(dockPosition)}
      transition={{ type: "spring", duration: 0.3 }}
      className={twMerge(
        // Custom class to override the some job list item styles (consolidate once this is the source of truth)
        "drawer group",

        "bg-white z-20 relative rounded-md border-gray-400 border",
        classNames({
          // Centre-docked w/ outline hack to overlap the build state border
          "absolute left-0 right-0 top-0 bottom-0 outline outline-1 outline-white":
            dockPosition === DockPosition.Center,

          // Expanded
          "fixed left-0 right-0 top-0 bottom-0 border-none": dockPosition === DockPosition.Full,
        }),
      )}
    >
      <div>
        <div className="hidden md:!block">
          {dockPosition === DockPosition.Bottom && (
            <ResizeHandle
              onResize={resize}
              direction="vertical"
              onDoubleClick={resetDrawerHeight}
            />
          )}

          {dockPosition === DockPosition.Right && (
            <ResizeHandle
              onResize={resize}
              direction="horizontal"
              onDoubleClick={resetDrawerWidth}
            />
          )}
        </div>

        <div className="absolute h-full w-full left-0 top-0 overflow-auto rounded-md">
          <div className="flex flex-col px-3">
            <div className="flex gap-2 sticky top-0 z-10 bg-white py-2">
              <ToolbarButton onClick={onClose} data-testid="drawer-close-button">
                <Icon icon="heroicons/20/solid/x-mark" className="h-5" />
                <span className="block md:hidden pr-2">Close</span>
              </ToolbarButton>

              {actions}

              <div className="hidden md:!flex gap-1 ml-auto">
                <DockPositions onClose={onClose} />
              </div>
            </div>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const positions = [
  {
    icon: "custom/outline/panel-bottom",
    position: DockPosition.Bottom,
  },
  {
    icon: "custom/outline/panel-right",
    position: DockPosition.Right,
  },
  {
    icon: "custom/outline/panel",
    position: DockPosition.Center,
  },
  {
    icon: "custom/outline/maximize",
    position: DockPosition.Full,
  },
];

/**
 * A collection of buttons to change the dock position of the drawer.
 *
 * Clicking a button will change the dock position and if the dock position is already selected, the drawer will close.
 */
const DockPositions = ({ onClose }: { onClose: () => void }) => {
  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);
  const setDockPosition = useBuildPreferencesStore((state) => state.setDockPosition);

  return (
    <ToolbarButtonGroup>
      {positions.map(({ icon, position }) => (
        <ToolbarButton
          key={position}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            // Close the drawer if the active button is clicked again
            if (dockPosition === position) {
              onClose();
            } else {
              setDockPosition(position);
            }
          }}
          isActive={dockPosition === position}
        >
          <Icon icon={icon} className="h-4" />
        </ToolbarButton>
      ))}
    </ToolbarButtonGroup>
  );
};
